
import { defineComponent, useContext, useFetch, ref, Ref } from '@nuxtjs/composition-api'
import BlogHighlightTile from '~/components/blog/BlogHighlightTile.vue'

export default defineComponent({
  name: 'BlogHighlights',
  components: { BlogHighlightTile },
  props: {
    postCategoryId: {
      type: String,
      required: false,
      default: null
    },
    postId: {
      type: String,
      required: false,
      default: null
    },
    limit: {
      type: Number,
      required: false,
      default: 3
    }
  },
  setup (props) {
    const { $axios } = useContext()
    const posts: Ref<any|null> = ref(null)

    useFetch(async () => {
      let url = `https://public-api.wordpress.com/wp/v2/sites/veke.blog/posts?per_page=${props.limit}&_fields[]=id&_fields[]=title&_fields[]=jetpack_featured_media_url&_fields[]=date&_fields[]=excerpt&_fields[]=slug`
      if (props.postCategoryId) {
        url += `&categories=${props.postCategoryId}`
      }
      if (props.postId) {
        url += `&include=${props.postId}`
      }
      posts.value = await $axios.get(url)
        .then((result: any) => {
          return result.data
        })
        .catch((err: any) => {
          console.error(err)
          return null
        })
    })

    return { posts }
  }
})
